import { Col, Row } from "react-bootstrap"
import { useCounterDays } from "../../hooks/counterDays/useCounterDays";

export const Countdown = ({ date } : { date: any}) => {
    const [days, hours, minutes, seconds] = useCounterDays(date);
    
    return (
        <Row className="contador-container">
            <Col className="contador">
                <p className="time-block">
                    <span className="time-block_number">{days}:</span>
                    <span className="time-block_text">días</span>
                </p>
                <p className="time-block">
                    <span className="time-block_number">{hours}:</span>
                    <span className="time-block_text">horas</span>
                </p>
                <p className="time-block">
                    <span className="time-block_number">{minutes}</span>
                    <span className="time-block_text">mins</span>
                </p>
            </Col>
        </Row>
    );
};